<script>
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import appConfig from "@/app.config";

/**
 * FAQ Guide
 */
export default {
  page: {
    title: "Guide für RWA Analyse-Funktionen",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Guide für RWA Analyse-Funktionen",
      items: [
        {
          text: "Dashbaord",
          href: "/"
        },
        {
          text: "Guides",
          href: "/guides"
        },
        {
          text: "FAQ",
          active: true
        }
      ]
    };
  },
  methods: {
    clickOnUploadGuide() {}
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-12">
                <div role="tablist">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab">
                      <h6 class="m-0">
                        <a
                          v-b-toggle.accordion-11
                          class="text-dark"
                          href="javascript: void(0);"
                          >Redem in der Praxis. Wie und wann setze ich Redem in
                          meinem Projektprozess ein?</a
                        >
                      </h6>
                    </b-card-header>
                    <b-collapse
                      id="accordion-11"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>
                          <ol>
                            <li>Projektive Fragen in Fragebogen einbauen</li>
                            <li class="mt-1">Durchführung der Befragung</li>
                            <li class="mt-1">
                              Manuelles Datenclearing (Optional)
                            </li>
                            <li class="mt-1">
                              Demographische Gewichtung oder andere Gewichtungen
                            </li>
                            <li class="mt-1">
                              Datenfile inkl. zusätzlichem Gewichtungsfaktor in
                              Redem-Web-App hochladen
                            </li>
                            <li class="mt-1">Gewichtung durch Redem</li>
                            <li class="mt-1">
                              Redem Gewichtung mit anderer Gewichtung
                              kombinieren
                            </li>
                          </ol>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
              <div class="col-xl-12">
                <div role="tablist">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab">
                      <h6 class="m-0">
                        <a
                          v-b-toggle.accordion-12
                          class="text-dark"
                          href="javascript: void(0);"
                          >Was sind Credits</a
                        >
                      </h6>
                    </b-card-header>
                    <b-collapse
                      id="accordion-12"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>
                          <ul>
                            <li>
                              Credits werden als Recheneinheit für das Hochladen
                              von Datenfiles verwendet.
                            </li>
                            <li class="mt-1">
                              Ein Datensatz entspricht dabei 1 Credit.
                            </li>
                            <li class="mt-1">
                              Für das Hochladen wird eine allgemeine Upload
                              Gebühr von 500 Credits zusätzlich berechnet.
                            </li>
                            <li class="mt-1">
                              Credits haben kein Ablaufdatum.
                            </li>
                            <li class="mt-1">
                              <b>Beispielrechnung</b>
                            </li>
                            <ul class="mt-1">
                              <li>800 Respondenten</li>
                              <ul>
                                <li>
                                  800 Credits + 500 Credits Upload-Gebühr = 1300
                                  Credits
                                </li>
                              </ul>
                            </ul>
                          </ul>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
              <div class="col-xl-12">
                <div role="tablist">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab">
                      <h6 class="m-0">
                        <a
                          v-b-toggle.accordion-13
                          class="text-dark"
                          href="javascript: void(0);"
                          >Warum benötigen wir all diese Daten beim Upload
                          Prozess?</a
                        >
                      </h6>
                    </b-card-header>
                    <b-collapse
                      id="accordion-13"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>
                          <ul>
                            <li>
                              Wir benötigen die Angaben um die Algorithmen,
                              optimal auf Ihre Daten abzustimmen.
                            </li>
                          </ul>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
              <div class="col-xl-12">
                <div role="tablist">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab">
                      <h6 class="m-0">
                        <a
                          v-b-toggle.accordion-14
                          class="text-dark"
                          href="javascript: void(0);"
                          >Was passiert mit jenen Daten, die Teil des Datenfiles
                          sind, jedoch nicht spezifiziert wurden?</a
                        >
                      </h6>
                    </b-card-header>
                    <b-collapse
                      id="accordion-14"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>
                          <ul>
                            <li>
                              Alle zusätzlich hochgeladenen Daten werden nicht
                              ohne Ihre Zustimmung verwendet.
                            </li>
                          </ul>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
              <div class="col-xl-12">
                <div role="tablist">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab">
                      <h6 class="m-0">
                        <a
                          v-b-toggle.accordion-15
                          class="text-dark"
                          href="javascript: void(0);"
                          >Ist die Datenverarbeitung von Redem DSGVO konform?</a
                        >
                      </h6>
                    </b-card-header>
                    <b-collapse
                      id="accordion-15"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>
                          <ul>
                            <li>
                              All Ihre Daten werden DSGVO konform verarbeitet.
                              Mehr Informationen dazu finden Sie hier
                              <a
                                href="https://redem.io/gdpr.html"
                                target="_blank"
                              >
                                https://redem.io/gdpr.html
                              </a>
                            </li>
                          </ul>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
