var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xl-12" }, [
                  _c(
                    "div",
                    { attrs: { role: "tablist" } },
                    [
                      _c(
                        "b-card",
                        { staticClass: "mb-1", attrs: { "no-body": "" } },
                        [
                          _c(
                            "b-card-header",
                            { attrs: { "header-tag": "header", role: "tab" } },
                            [
                              _c("h6", { staticClass: "m-0" }, [
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName: "v-b-toggle.accordion-11",
                                        modifiers: { "accordion-11": true },
                                      },
                                    ],
                                    staticClass: "text-dark",
                                    attrs: { href: "javascript: void(0);" },
                                  },
                                  [
                                    _vm._v(
                                      "Redem in der Praxis. Wie und wann setze ich Redem in meinem Projektprozess ein?"
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "accordion-11",
                                visible: "",
                                accordion: "my-accordion",
                                role: "tabpanel",
                              },
                            },
                            [
                              _c(
                                "b-card-body",
                                [
                                  _c("b-card-text", [
                                    _c("ol", [
                                      _c("li", [
                                        _vm._v(
                                          "Projektive Fragen in Fragebogen einbauen"
                                        ),
                                      ]),
                                      _c("li", { staticClass: "mt-1" }, [
                                        _vm._v("Durchführung der Befragung"),
                                      ]),
                                      _c("li", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          " Manuelles Datenclearing (Optional) "
                                        ),
                                      ]),
                                      _c("li", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          " Demographische Gewichtung oder andere Gewichtungen "
                                        ),
                                      ]),
                                      _c("li", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          " Datenfile inkl. zusätzlichem Gewichtungsfaktor in Redem-Web-App hochladen "
                                        ),
                                      ]),
                                      _c("li", { staticClass: "mt-1" }, [
                                        _vm._v("Gewichtung durch Redem"),
                                      ]),
                                      _c("li", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          " Redem Gewichtung mit anderer Gewichtung kombinieren "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-xl-12" }, [
                  _c(
                    "div",
                    { attrs: { role: "tablist" } },
                    [
                      _c(
                        "b-card",
                        { staticClass: "mb-1", attrs: { "no-body": "" } },
                        [
                          _c(
                            "b-card-header",
                            { attrs: { "header-tag": "header", role: "tab" } },
                            [
                              _c("h6", { staticClass: "m-0" }, [
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName: "v-b-toggle.accordion-12",
                                        modifiers: { "accordion-12": true },
                                      },
                                    ],
                                    staticClass: "text-dark",
                                    attrs: { href: "javascript: void(0);" },
                                  },
                                  [_vm._v("Was sind Credits")]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "accordion-12",
                                visible: "",
                                accordion: "my-accordion",
                                role: "tabpanel",
                              },
                            },
                            [
                              _c(
                                "b-card-body",
                                [
                                  _c("b-card-text", [
                                    _c("ul", [
                                      _c("li", [
                                        _vm._v(
                                          " Credits werden als Recheneinheit für das Hochladen von Datenfiles verwendet. "
                                        ),
                                      ]),
                                      _c("li", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          " Ein Datensatz entspricht dabei 1 Credit. "
                                        ),
                                      ]),
                                      _c("li", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          " Für das Hochladen wird eine allgemeine Upload Gebühr von 500 Credits zusätzlich berechnet. "
                                        ),
                                      ]),
                                      _c("li", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          " Credits haben kein Ablaufdatum. "
                                        ),
                                      ]),
                                      _c("li", { staticClass: "mt-1" }, [
                                        _c("b", [_vm._v("Beispielrechnung")]),
                                      ]),
                                      _c("ul", { staticClass: "mt-1" }, [
                                        _c("li", [_vm._v("800 Respondenten")]),
                                        _c("ul", [
                                          _c("li", [
                                            _vm._v(
                                              " 800 Credits + 500 Credits Upload-Gebühr = 1300 Credits "
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-xl-12" }, [
                  _c(
                    "div",
                    { attrs: { role: "tablist" } },
                    [
                      _c(
                        "b-card",
                        { staticClass: "mb-1", attrs: { "no-body": "" } },
                        [
                          _c(
                            "b-card-header",
                            { attrs: { "header-tag": "header", role: "tab" } },
                            [
                              _c("h6", { staticClass: "m-0" }, [
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName: "v-b-toggle.accordion-13",
                                        modifiers: { "accordion-13": true },
                                      },
                                    ],
                                    staticClass: "text-dark",
                                    attrs: { href: "javascript: void(0);" },
                                  },
                                  [
                                    _vm._v(
                                      "Warum benötigen wir all diese Daten beim Upload Prozess?"
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "accordion-13",
                                visible: "",
                                accordion: "my-accordion",
                                role: "tabpanel",
                              },
                            },
                            [
                              _c(
                                "b-card-body",
                                [
                                  _c("b-card-text", [
                                    _c("ul", [
                                      _c("li", [
                                        _vm._v(
                                          " Wir benötigen die Angaben um die Algorithmen, optimal auf Ihre Daten abzustimmen. "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-xl-12" }, [
                  _c(
                    "div",
                    { attrs: { role: "tablist" } },
                    [
                      _c(
                        "b-card",
                        { staticClass: "mb-1", attrs: { "no-body": "" } },
                        [
                          _c(
                            "b-card-header",
                            { attrs: { "header-tag": "header", role: "tab" } },
                            [
                              _c("h6", { staticClass: "m-0" }, [
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName: "v-b-toggle.accordion-14",
                                        modifiers: { "accordion-14": true },
                                      },
                                    ],
                                    staticClass: "text-dark",
                                    attrs: { href: "javascript: void(0);" },
                                  },
                                  [
                                    _vm._v(
                                      "Was passiert mit jenen Daten, die Teil des Datenfiles sind, jedoch nicht spezifiziert wurden?"
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "accordion-14",
                                visible: "",
                                accordion: "my-accordion",
                                role: "tabpanel",
                              },
                            },
                            [
                              _c(
                                "b-card-body",
                                [
                                  _c("b-card-text", [
                                    _c("ul", [
                                      _c("li", [
                                        _vm._v(
                                          " Alle zusätzlich hochgeladenen Daten werden nicht ohne Ihre Zustimmung verwendet. "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-xl-12" }, [
                  _c(
                    "div",
                    { attrs: { role: "tablist" } },
                    [
                      _c(
                        "b-card",
                        { staticClass: "mb-1", attrs: { "no-body": "" } },
                        [
                          _c(
                            "b-card-header",
                            { attrs: { "header-tag": "header", role: "tab" } },
                            [
                              _c("h6", { staticClass: "m-0" }, [
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName: "v-b-toggle.accordion-15",
                                        modifiers: { "accordion-15": true },
                                      },
                                    ],
                                    staticClass: "text-dark",
                                    attrs: { href: "javascript: void(0);" },
                                  },
                                  [
                                    _vm._v(
                                      "Ist die Datenverarbeitung von Redem DSGVO konform?"
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: "accordion-15",
                                visible: "",
                                accordion: "my-accordion",
                                role: "tabpanel",
                              },
                            },
                            [
                              _c(
                                "b-card-body",
                                [
                                  _c("b-card-text", [
                                    _c("ul", [
                                      _c("li", [
                                        _vm._v(
                                          " All Ihre Daten werden DSGVO konform verarbeitet. Mehr Informationen dazu finden Sie hier "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "https://redem.io/gdpr.html",
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " https://redem.io/gdpr.html "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }